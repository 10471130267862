import { AnalyticsBrowser } from "@segment/analytics-next";
import { APP_BUILD_NUMBER, SEGMENT_WRITE_KEY } from "./env";

const analytics =
	typeof window != "undefined" ? new AnalyticsBrowser() : undefined;

// We're adding a build number to events so we can track if old events are being fired
analytics?.addSourceMiddleware(({ payload, next }) => {
	const searchParams = new URL(location.href).searchParams;
	const context = payload.obj.context;
	if (context) {
		context.app = {
			...(context.app || {}),
			build: APP_BUILD_NUMBER,
		};
		context.zipCode = searchParams.get("zipCode");
	}
	next(payload);
});
analytics?.load({ writeKey: SEGMENT_WRITE_KEY });

export { analytics };
