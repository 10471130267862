"use client";

import {
	ButtonProps,
	default as HomeDesignButton,
} from "@clayton-homes/home-designer/components/button";
import Link, { LinkProps } from "next/link";
import { HTMLAttributes } from "react";

const buttonStyles = {
	"primary-solid":
		"border-0 bg-primary-500 !text-white no-underline hover:bg-primary-400",
	"primary-outline":
		"border border-primary-500 bg-white !text-primary-500 no-underline hover:bg-primary-50",
	white:
		"border border-white bg-white !text-primary-500 no-underline hover:bg-primary-50",
	none: "",
} as const;

const buttonSizes = {
	default:
		"text-button-lg inline-flex justify-center items-center gap-1 cursor-pointer rounded-sm px-5 py-4 leading-none",
	small:
		"text-button-lg inline-flex justify-center items-center gap-1 cursor-pointer rounded-sm px-3 py-2 leading-none",
	none: "",
};

const disabledStyles =
	"aria-disabled:cursor-default aria-disabled:bg-gray-300 aria-disabled:text-gray-500";

type TButtonProps<E> = ButtonProps &
	E & {
		type?: HTMLButtonElement["type"];
		buttonStyle?: keyof typeof buttonStyles;
		buttonSize?: keyof typeof buttonSizes;
	};

export function ButtonLink({
	className = "",
	buttonStyle = "primary-solid",
	buttonSize = "default",
	children,
	href,
	overrideClassName,
	...props
}: TButtonProps<LinkProps>) {
	return (
		<HomeDesignButton
			{...props}
			overrideClassName
			as={Link}
			role="link"
			href={href}
			className={`${className} ${disabledStyles} ${buttonStyles[buttonStyle]} ${buttonSizes[buttonSize]}`}
		>
			{children}
		</HomeDesignButton>
	);
}

export function Button({
	className = "",
	buttonStyle = "primary-solid",
	buttonSize = "default",
	children,
	...props
}: TButtonProps<HTMLAttributes<HTMLButtonElement>>) {
	return (
		<HomeDesignButton
			{...props}
			overrideClassName
			className={`${className} ${disabledStyles} ${buttonStyles[buttonStyle]} ${buttonSizes[buttonSize]}`}
		>
			{children}
		</HomeDesignButton>
	);
}
