import SvgClose from "@clayton-homes/ui-icons/24/close";
import SvgHamburgerMenu from "@clayton-homes/ui-icons/24/hamburger-menu";
import Link from "next/link";
import { useSearchParams } from "next/navigation";
import { useState } from "react";
import { ButtonLink } from "../client.button";

export default function MobileMenu({
	noBackgroundPage,
	onMenuOpen,
}: {
	noBackgroundPage: boolean;
	onMenuOpen: (isOpen: boolean) => void;
}) {
	const [isNavMenuOpen, setIsNavMenuOpen] = useState(false);
	const searchParams = useSearchParams();
	const zipCode = searchParams?.get("zipCode") || "";

	return (
		<>
			<SvgHamburgerMenu
				className={`${
					isNavMenuOpen ? "hidden" : "block"
				} z-40 h-10 w-10 transition-colors duration-300 2xl:hidden`}
				onClick={(event) => {
					event.preventDefault();
					setIsNavMenuOpen(true);
					onMenuOpen(true);
				}}
			/>
			<div
				className={`${isNavMenuOpen ? "block" : "hidden"} z-40 h-10 w-10 2xl:hidden`}
				onClick={(event) => {
					event.preventDefault();
					setIsNavMenuOpen(false);
					onMenuOpen(false);
				}}
			>
				<SvgClose />
			</div>
			<div
				className={`${
					isNavMenuOpen ? "translate-y-0 shadow-lg" : "-translate-y-[130%]"
				} text-paragraph absolute left-0 top-0 z-20 flex w-full flex-col items-center gap-6 bg-white transition-all duration-500 ease-in-out 2xl:static 2xl:z-40 2xl:transform-none 2xl:flex-row 2xl:bg-transparent 2xl:py-0 2xl:text-[inherit] 2xl:shadow-none 2xl:transition-none`}
			>
				<div className="h-16 w-full border-b border-b-gray-300 bg-white 2xl:hidden"></div>
				<Link
					className={`${
						noBackgroundPage ? "hover:2xl:bg-primary-400" : ""
					} mt-6 p-2 hover:bg-gray-50 2xl:mt-0`}
					href={{
						pathname: "/how-it-works",
						query: { zipCode: zipCode },
					}}
				>
					How It Works
				</Link>
				<Link
					className={`${
						noBackgroundPage ? "hover:2xl:bg-primary-400" : ""
					} p-2 hover:bg-gray-50`}
					href={{
						pathname: "/homes",
						query: { zipCode: zipCode },
					}}
				>
					Homes
				</Link>
				<hr className="w-11/12 border-gray-300 2xl:hidden" />
				<ButtonLink
					href={{
						pathname: "/find/size",
						query: { zipCode: zipCode },
					}}
					className="mb-6 inline-block 2xl:mb-0 2xl:ml-auto"
				>
					Design Your Home
				</ButtonLink>
			</div>
		</>
	);
}
