import { useEffect, useState } from "react";
import { CLAYTON_API, CLAYTON_API_IDENTIFIER } from "../utilities/env";

export type TLocationInfo = {
	postalCode: string;
	city: string;
	county: string;
	state: string;
	latitude: number;
	longitude: number;
	cityStateFormatted: string;
};

export type TResultStatus = "idle" | "loading" | "loaded" | "error";

export function useLocationSearchDebounce(value: string, delay: number) {
	const [debouncedValue, setDebouncedValue] = useState<TLocationInfo[]>([]);
	const [status, setStatus] = useState<TResultStatus>("idle");

	useEffect(() => {
		const handler = setTimeout(() => {
			if (!value || value.length < 3) {
				setDebouncedValue([]);
				setStatus("idle");
			} else {
				setStatus("loading");
				fetch(`${CLAYTON_API}/v2/location/geocode/autocomplete/${value}`, {
					headers: {
						"Api-Key": CLAYTON_API_IDENTIFIER,
					},
				})
					.then((r) => r.json())
					.then((d) => {
						setDebouncedValue(d);
						setStatus("loaded");
					})
					.catch((e) => {
						setDebouncedValue([]);
						setStatus("error");
					});
			}
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [value, delay]);

	return { status, results: debouncedValue };
}

export default useLocationSearchDebounce;
