import Transition from "@clayton-homes/home-designer/components/transition";
import { isKey, key } from "@clayton-homes/home-designer/util/keyboard";
import SvgChevronDown from "@clayton-homes/ui-icons/24/chevron-down";
import SvgMapMarker2 from "@clayton-homes/ui-icons/24/map-marker-2.js";
import {
	FloatingFocusManager,
	FloatingOverlay,
	autoUpdate,
	useDismiss,
	useFloating,
	useInteractions,
	useRole,
} from "@floating-ui/react";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useCallback, useEffect, useId, useRef, useState } from "react";
import useLocationSearchDebounce, {
	TLocationInfo,
	TResultStatus,
} from "../../hooks/use-location-search-debounce";
import { AnalyticEventNames } from "../../utilities/env";
import { analytics } from "../../utilities/segment";

interface IZipCodeDialogProps {
	noBackground: boolean;
}

const zipRegExp = /^\d{5}$/;

const ListBox = ({
	listID,
	results,
	highlightedZipCode,
	status,
	handleSelect,
}: {
	listID: string;
	results: TLocationInfo[];
	highlightedZipCode: string;
	status: TResultStatus;
	handleSelect: (zipCode: string) => void;
}) => {
	if (status === "loading") {
		return null;
	}
	if (status === "idle") {
		return null;
	}
	if (status === "error") {
		return <div className="text-xs px-3 py-2">Sorry, there was an error.</div>;
	}
	return (
		<ul id={listID} role="listbox" className="text-xs space-y-1">
			{results.map((l) => (
				<li
					key={l.postalCode}
					tabIndex={-1}
					role="option"
					data-highlight={
						highlightedZipCode === l.postalCode ? "true" : "false"
					}
					className={`flex items-center gap-2 bg-gray-300 px-3 py-2 text-gray-700 hover:cursor-pointer hover:bg-primary-500 hover:text-white data-[highlight=true]:bg-primary-500 data-[highlight=true]:text-white`}
					onClick={() => {
						handleSelect(l.postalCode);
					}}
				>
					<SvgMapMarker2 className="h-4 w-4" />
					{l.cityStateFormatted}
				</li>
			))}
		</ul>
	);
};

const ZipCodeDialog = Object.assign(function ({
	noBackground,
}: IZipCodeDialogProps) {
	const ref = useRef<HTMLDivElement>(null);
	const [open, setOpen] = useState(false);
	const [display, setDisplay] = useState(open);
	const { refs, context } = useFloating({
		open: open,
		onOpenChange: (v) => {
			setOpen(v);
		},
		placement: "bottom",
		whileElementsMounted: autoUpdate,
	});
	const dismiss = useDismiss(context);
	const role = useRole(context, { role: "dialog" });
	const interactions = useInteractions([dismiss, role]);

	const [searchInput, setSearchInput] = useState("");
	const { status, results } = useLocationSearchDebounce(searchInput, 250);
	const listID = useId();
	const [highlightedZipCode, setHighlightedZipCode] = useState("");
	const [rawInputError, setRawInputError] = useState("");

	const searchParams = useSearchParams();
	const pathname = usePathname();
	const router = useRouter();

	const createQueryString = useCallback(
		(name: string, value: string) => {
			const params = new URLSearchParams(searchParams?.toString());
			params.set(name, value);
			return params.toString();
		},
		[searchParams],
	);

	function onSelect(zipCode: string) {
		analytics?.track(AnalyticEventNames.locationUpdated, {
			zipCode,
		});
		router.replace(`${pathname}?${createQueryString("zipCode", zipCode)}`);
	}

	useEffect(() => {
		if (open) setDisplay(open);
	}, [open]);
	useEffect(() => {
		ref.current?.querySelector("[data-highlight=true]")?.scrollIntoView(false);
	}, [highlightedZipCode]);
	return (
		<div ref={ref}>
			<button
				className="relative inline-block"
				ref={refs.setReference}
				onClick={() => setOpen(true)}
				{...interactions.getReferenceProps()}
				title="Open update ZIP code dialog"
				data-name="zip-code-dialog"
			>
				<div
					className={`flex h-fit ${
						noBackground ? "text-white" : "text-primary-500"
					}`}
				>
					<span
						className={`${
							noBackground ? "border-b-white" : "border-b-primary-500"
						} h-5 border-b border-b-primary-500`}
					>
						{searchParams?.get("zipCode")}
					</span>
					<SvgChevronDown className="my-1 ml-1 h-3 w-3" />
				</div>
			</button>
			{display ? (
				<>
					<FloatingOverlay
						onClick={() => {
							setOpen(false);
						}}
						lockScroll
					>
						<Transition
							show={open}
							enter="transition-opacity duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-70"
							leave="transition-opacity duration-300"
							leaveTo="opacity-0"
							onLeaveComplete={() => {
								setDisplay(false);
							}}
						>
							<div className={`absolute inset-0 bg-black`} />
						</Transition>
					</FloatingOverlay>
					<FloatingFocusManager context={context} modal visuallyHiddenDismiss>
						<Transition
							show={open}
							enterFrom="opacity-0"
							enter="transition-opacity duration-300"
							enterTo="opacity-100"
							leave="transition-opacity duration-300"
							leaveTo="opacity-0"
						>
							<div
								ref={refs.setFloating}
								className={`md:absolute! fixed bottom-0 left-0 right-0 top-auto z-50 flex flex-col-reverse border border-gray-700 bg-white p-6 md:absolute md:bottom-auto md:left-auto md:right-auto md:w-72 md:flex-col`}
								{...interactions.getFloatingProps()}
							>
								<div className="w-full">
									<div className="text-label pt-4 uppercase text-primary-500 md:pt-0">
										Enter city or zip code
									</div>
									<input
										className="text-label w-full border-b-[0.25px] border-gray-300 px-0 py-2 focus-visible:outline-none"
										value={searchInput}
										type="text"
										role="combobox"
										aria-expanded={results.length > 0}
										aria-autocomplete="list"
										aria-controls={listID}
										onInput={(e) => {
											setSearchInput(e.currentTarget.value);
											setHighlightedZipCode("");
										}}
										onKeyUp={(e) => {
											const updateZipCode = (zipCode: string) => {
												setHighlightedZipCode(zipCode);
												onSelect(zipCode);
												setOpen(false);
												setRawInputError("");
											};
											if (!isKey(e, key.Enter)) return;
											if (highlightedZipCode != "") {
												updateZipCode(highlightedZipCode);
											} else if (results.length) {
												updateZipCode(results[0].postalCode);
											} else if (e.currentTarget.value === "") {
												setRawInputError("Please provide a zip code.");
											} else if (
												!results.length &&
												!zipRegExp.test(e.currentTarget.value)
											) {
												setRawInputError("Zip code should be 5 numbers.");
											} else if (zipRegExp.test(e.currentTarget.value)) {
												updateZipCode(e.currentTarget.value);
											}
										}}
										onFocus={(e) =>
											e.target.setSelectionRange(0, e.target.value.length)
										}
										onKeyDown={(e) => {
											if (!results.length) return;
											const isDown = isKey(e, key.Down);
											const isUp = isKey(e, key.Up);
											const currentIndex = results.findIndex(
												(i) => i.postalCode === highlightedZipCode,
											);
											if (isDown || isUp) {
												e.preventDefault();
												e.stopPropagation();
											}
											if (
												isDown &&
												(currentIndex === -1 ||
													currentIndex === results.length - 1)
											) {
												setHighlightedZipCode(results[0].postalCode);
											} else if (isDown) {
												setHighlightedZipCode(
													results[currentIndex + 1].postalCode,
												);
											} else if (
												isUp &&
												(currentIndex === -1 || currentIndex === 0)
											) {
												setHighlightedZipCode(
													results[results.length - 1].postalCode,
												);
											} else if (isUp) {
												setHighlightedZipCode(
													results[currentIndex - 1].postalCode,
												);
											}
										}}
										autoComplete="off"
									/>
								</div>
								<div className="grid grid-cols-1">
									{rawInputError !== "" ? (
										<div className="text-xs col-start-1 row-start-1 h-fit bg-error px-2 py-1 text-white">
											{rawInputError}
										</div>
									) : null}
									<div className="col-start-1 row-start-1 max-h-64 overflow-y-auto">
										<ListBox
											listID={listID}
											results={results}
											highlightedZipCode={highlightedZipCode}
											status={status}
											handleSelect={(zipCode) => {
												setRawInputError("");
												onSelect(zipCode);
												setOpen(false);
											}}
										/>
									</div>
								</div>
							</div>
						</Transition>
					</FloatingFocusManager>
				</>
			) : null}
		</div>
	);
}, {});

export default ZipCodeDialog;
